import React from 'react';
import { PageProps } from 'gatsby';

import { Layout } from '../../components/layouts';
import { SEO } from '../../components/common';
import { BlogIndexSection } from '../../components/sections';

const BlogIndexPage = ({ params }: PageProps): JSX.Element => {
  return (
    <Layout>
      <SEO
        title="Best Mattress Store in Singapore, Buy Mattress Singapore Online Shop"
        description="Complete your nights with Woosa's pillows, bases, and some of the best mattress products in Singapore. Contact us or visit our showroom to find out more."
      />
      <BlogIndexSection params={params} />
    </Layout>
  );
};

export default BlogIndexPage;
